.image-trigger {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: black;
  margin: 0 5px;
  text-align: center;
  width: 150px;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
  cursor: pointer;
  overflow: hidden;
  &__text {
    position: absolute;
    z-index: 2;
    align-self: center;
    text-align: center;
    color: white;
    font-size: 16px;
  }
  & > img {
    display: block;
    text-align: center;
  }
}

.ui.active.modal {
  margin: 80px;
}


