.card-images {
  display: flex;
  overflow: auto;
  box-sizing: border-box;
  height: 150px;

  .ui.images {
    display: flex;
  }

  &--loader {
    position: relative;
    width: 100%;
    height: 150px;
  }
}

