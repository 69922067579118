@import '../../static/variables';

$form-page-width: 700px;
$form-width: 320px;
$form-inputs-gap: 1em;
$page-border-color: #BCBABA;

.authentication-page {
  max-width: $form-page-width;
  margin: 4em auto 0 auto;
  padding: 4em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $page-bg-color;
  color: $text-color;
  border:1px solid $page-border-color;

  &__header {
    line-height: inherit !important;
  }

  &__form {
    width: $form-width;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 1em;
  }
  &__recovery-link {
    margin-top: $form-inputs-gap;
    cursor: pointer;
  }
  //semantic ui
  .message {
    max-width: $form-width !important;
    margin-top: 0 !important;
    width: 100%;
  }
  .loader {
    position: static !important;
    margin-top: 2em !important;
  }
  .button {
    margin-right: 0 !important;
  }
  input {
    color: $text-color !important;
  }
}
