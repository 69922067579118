@import 'src/static/variables';

.dialogs {
  width: 97%;
  margin: 15px auto 0;
  padding: 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  &__header {
    display: flex;
    padding-left: 10px;
    align-items: center;
    height: 25px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    overflow: hidden;
    background-color: $brand-color;
    color: $page-bg-color;
  }
  &__items {
    height: 150px;
    overflow: auto;
  }
  &__item {
    height: 50px;
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &__header {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      &--name {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__message {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
