@import '../../../static/variables';

.add-seller {
  &__search-form {
    margin-bottom: $input-margin !important;
    .field {
      max-width: 80% !important;
    }
  }
  &__add-button {
    margin-top: $input-margin !important;
  }
}
//Semantic ui
.loader {
  &::after {
    border-top-color: #ccc !important;
  }
}