@import '../../static/variables';

$notification-color: $bg-color;

.navigation {
  margin: 0 !important;
  &__menu {
    background: $page-bg-color !important;
    border-radius: $page-border-radius $page-border-radius 0 0 !important;
  }

  .menu {
    &__logo {
      background: $brand-color !important;
      &:active, &:hover {
        background-color: $brand-color !important;
      }
    }
  }

  //Semantic UI
  .ui.menu .ui.dropdown .menu > .item {
    font-size: .85em !important;
    color: $text-color !important;
    &:hover, &.active {
      background-color: $bg-color !important;
    }
  }
  .menu {
    border: 0 !important;
    &__dropdown {
      background: $page-bg-color !important;
      color: $text-color !important;
      .item {
        height: 60px !important;
      }
    }
  }
  .item {
    display: flex !important;
    border-radius: 0 !important;
    color: $text-color !important;
    font-size: 1.2em;
    font-weight: bold !important;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    &:hover, &.active {
      background-color: $page-bg-color !important;
      border-top: 3px solid $text-color;
    }
  }
}
