
$brand-color: #DA291C;
$brand-color_hover: rgba(238, 42, 26, 0.8);
$page-bg-color: #FFFFFF;
$bg-color: #F0EDED;
$text-color: #484747;
$positive-color: #81BE39;
$positive-color-hover: #91d640;
$page-width: 1700px;
$page-border-radius: 4px;
$sticky-header-height: 200px;
$input-margin: 1em;
$border-color: rgba(0, 0, 0, 0.1);
