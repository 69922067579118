@import '../../static/variables.scss';

.boolean-cell, .radio-cell {
	display: flex;
	justify-content: center;
	i.green.icon {
		color: $positive-color !important;
	}
	i.red.icon {
		color: $brand-color !important;
	}
}
