@import 'src/static/variables';

.chat-complete {
  display: flex;
  width: 100%;
  border: $border-color 1px solid;
  &__header {
    display: flex;
    align-items: center;

    &--user {
      padding: 5px;
      width: 50%;
    }

    &--admin {
      padding: 5px;
      width: 50%;
    }
  }

  &__history {
    width: 100%;
    height: 500px;
    max-width: 700px;
    border-right: $border-color 1px solid;
    overflow: hidden;

    .chat__field__messages {
      height: 100% !important;
      padding-bottom: 80px;
    }
  }

  &__user-info {
    width: 100%;
    max-width: 400px;
  }

}