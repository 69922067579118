@import "variables";

.sticky-header-wrapper {
  height: $sticky-header-height;
  width: 100%;
  z-index: 0;
  background-color: transparent;
}

.sticky-header {
  box-shadow: 0 0 5px rgba(34,36,38,.25);
  height: $sticky-header-height;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}
